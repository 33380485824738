import React, {useEffect, useState} from "react";
import { Editor } from '@tinymce/tinymce-react';


const MainEditor = ({ editorStyles, style, className, required, value, tooltip, label, valid, errorMessage, onChange, height = 300, disabled, plugins, toolbar, menubar
}) => {
    const [load, setLoad] = useState(false)

    return <div style={ style }  className={className}>
        {
            (label) ? <label data-tooltip={tooltip} style={{display: "block"}}>
                {(tooltip) ? <div className="squerTooltipe"/> : null} {label} {(required) ? <span style={{color: "red"}}>*</span> : null}</label> : null
        }
        <Editor
            value={value}
            apiKey='1gdwftdlw8an1h01k6dwp1ozg9wynl4cuedzcdgfgm4gojno' // jodove7761@chainds.com
            init={{
                height: height,
                plugins: plugins || [
                    ' preview paste searchreplace autolink autosave save directionality visualblocks ' +
                    'visualchars fullscreen image link media codesample table charmap hr pagebreak nonbreaking ' +
                    'anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap emoticons code'
                ],
                menubar: menubar || 'file edit view insert format tools table help',
                toolbar: toolbar || 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect ' +
                    '| alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak ' +
                    '| charmap emoticons | fullscreen  preview print | insertfile image media template link anchor codesample code',
                toolbar_sticky: false,
                language: 'ru'
            }}
            style={{ border: (!valid) ? "1px solid red" : '', ...editorStyles }}
            onEditorChange={e => load && onChange(e)}
            onFocus={() => setLoad(true)}
            // onBlur={() => setLoad(false)}
            disabled={disabled}
        />
        {
            (!valid) ? <span className="errorMsg">{(errorMessage) || "Заполните поле"}</span> : null
        }
    </div>
}

export default MainEditor

/*
const MainEditor = ({ style, className, required, value, tooltip, label, valid, errorMessage, onChange }) => {
    return <div style={ style }  className={className}>
        {
            (label) ? <label data-tooltip={tooltip} style={{display: "block"}}>
                {(tooltip) ? <div className="squerTooltipe"/> : null} {label} {(required) ? <span style={{color: "red"}}>*</span> : null}</label> : null
        }
        <ReactQuill
            onChange={onChange}
            value={value || ''}
            modules={MainEditor.modules}
            formats={MainEditor.formats}
            bounds={'.app'}
            style={{ border: (!valid) ? "1px solid red" : '' }}
        />
        {
            (!valid) ? <span className="errorMsg">{(errorMessage) || "Заполните поле"}</span> : null
        }
    </div>
}

export default MainEditor;

//https://quilljs.com/docs/modules/
MainEditor.modules = {
    toolbar: [
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{'list': 'ordered'}, {'list': 'bullet'},
            {'indent': '-1'}, {'indent': '+1'}],
        [{ 'color': [] }, { 'background': [] }],
        ['link'],
    ],
    clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false,
    }
};
MainEditor.formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike',
    'list', 'bullet', 'indent',
    'link', 'color', 'background'
]*/
